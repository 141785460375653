/* eslint-disable */
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
};

export type ActivityLog = {
  __typename?: 'ActivityLog';
  action: Scalars['String'];
  createdAt: Scalars['DateTime'];
  details?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
  userId: Scalars['Float'];
};

export type ActivityLogCreateInput = {
  action: Scalars['String'];
  details?: InputMaybe<Scalars['String']>;
  userId: Scalars['Float'];
};

export type ActivityLogCreateManyUserInput = {
  action: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityLogCreateManyUserInputEnvelope = {
  data: Array<ActivityLogCreateManyUserInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type ActivityLogCreateNestedManyWithoutUserInput = {
  connect?: InputMaybe<Array<ActivityLogWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<ActivityLogCreateOrConnectWithoutUserInput>>;
  create?: InputMaybe<Array<ActivityLogCreateWithoutUserInput>>;
  createMany?: InputMaybe<ActivityLogCreateManyUserInputEnvelope>;
};

export type ActivityLogCreateOrConnectWithoutUserInput = {
  create: ActivityLogCreateWithoutUserInput;
  where: ActivityLogWhereUniqueInput;
};

export type ActivityLogCreateWithoutUserInput = {
  action: Scalars['String'];
  createdAt?: InputMaybe<Scalars['DateTime']>;
  details?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type ActivityLogListRelationFilter = {
  every?: InputMaybe<ActivityLogWhereInput>;
  none?: InputMaybe<ActivityLogWhereInput>;
  some?: InputMaybe<ActivityLogWhereInput>;
};

export type ActivityLogOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type ActivityLogWhereInput = {
  AND?: InputMaybe<Array<ActivityLogWhereInput>>;
  NOT?: InputMaybe<Array<ActivityLogWhereInput>>;
  OR?: InputMaybe<Array<ActivityLogWhereInput>>;
  action?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  details?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  user?: InputMaybe<UserRelationFilter>;
  userId?: InputMaybe<IntFilter>;
};

export type ActivityLogWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type AuthResponse = {
  __typename?: 'AuthResponse';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
  user: User;
};

export type BusinessCreateInput = {
  franchiseeId: Scalars['Float'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type BusinessUnit = {
  __typename?: 'BusinessUnit';
  createdAt: Scalars['DateTime'];
  franchiseeId: Scalars['Int'];
  id: Scalars['Float'];
  inventory: Array<InventoryItem>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type BusinessUnitCreateManyFranchiseeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessUnitCreateManyFranchiseeInputEnvelope = {
  data: Array<BusinessUnitCreateManyFranchiseeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type BusinessUnitCreateNestedManyWithoutFranchiseeInput = {
  connect?: InputMaybe<Array<BusinessUnitWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<BusinessUnitCreateOrConnectWithoutFranchiseeInput>>;
  create?: InputMaybe<Array<BusinessUnitCreateWithoutFranchiseeInput>>;
  createMany?: InputMaybe<BusinessUnitCreateManyFranchiseeInputEnvelope>;
};

export type BusinessUnitCreateNestedOneWithoutUsersInput = {
  connect?: InputMaybe<BusinessUnitWhereUniqueInput>;
  connectOrCreate?: InputMaybe<BusinessUnitCreateOrConnectWithoutUsersInput>;
  create?: InputMaybe<BusinessUnitCreateWithoutUsersInput>;
};

export type BusinessUnitCreateOrConnectWithoutFranchiseeInput = {
  create: BusinessUnitCreateWithoutFranchiseeInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateOrConnectWithoutUsersInput = {
  create: BusinessUnitCreateWithoutUsersInput;
  where: BusinessUnitWhereUniqueInput;
};

export type BusinessUnitCreateWithoutFranchiseeInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  inventory?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
  users?: InputMaybe<UserCreateNestedManyWithoutBusinessUnitInput>;
};

export type BusinessUnitCreateWithoutUsersInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisee: FranchiseeCreateNestedOneWithoutBusinessUnitsInput;
  inventory?: InputMaybe<InventoryItemCreateNestedManyWithoutBusinessUnitInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type BusinessUnitListRelationFilter = {
  every?: InputMaybe<BusinessUnitWhereInput>;
  none?: InputMaybe<BusinessUnitWhereInput>;
  some?: InputMaybe<BusinessUnitWhereInput>;
};

export type BusinessUnitOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type BusinessUnitOrderByWithRelationInput = {
  createdAt?: InputMaybe<SortOrder>;
  franchisee?: InputMaybe<FranchiseeOrderByWithRelationInput>;
  franchiseeId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  inventory?: InputMaybe<InventoryItemOrderByRelationAggregateInput>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
  users?: InputMaybe<UserOrderByRelationAggregateInput>;
};

export type BusinessUnitRelationFilter = {
  is?: InputMaybe<BusinessUnitWhereInput>;
  isNot?: InputMaybe<BusinessUnitWhereInput>;
};

export type BusinessUnitWhereInput = {
  AND?: InputMaybe<Array<BusinessUnitWhereInput>>;
  NOT?: InputMaybe<Array<BusinessUnitWhereInput>>;
  OR?: InputMaybe<Array<BusinessUnitWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisee?: InputMaybe<FranchiseeRelationFilter>;
  franchiseeId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  inventory?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  users?: InputMaybe<UserListRelationFilter>;
};

export type BusinessUnitWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type CategoryInput = {
  categoryId: Scalars['Int'];
};

export type CloseInventoryInput = {
  items: Array<UpdateInventoryQuantityInput>;
};

export type CreateInventoryItemInput = {
  businessUnitId: Scalars['Float'];
  categories: Array<CategoryInput>;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  place: Place;
  positionIndex: Scalars['Float'];
  quantity: Scalars['Float'];
  unitOfMeasureId: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type CreateStockUpdateInput = {
  inventoryItemId: Scalars['Float'];
  observation?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type EnumPlaceFilter = {
  equals?: InputMaybe<Place>;
  in?: InputMaybe<Array<Place>>;
  not?: InputMaybe<NestedEnumPlaceFilter>;
  notIn?: InputMaybe<Array<Place>>;
};

export type EnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type Franchisee = {
  __typename?: 'Franchisee';
  businessUnits?: Maybe<Array<BusinessUnit>>;
  createdAt: Scalars['DateTime'];
  franchisor?: Maybe<Franchisor>;
  franchisorId: Scalars['Int'];
  id: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type FranchiseeCreateManyFranchisorInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeCreateManyFranchisorInputEnvelope = {
  data: Array<FranchiseeCreateManyFranchisorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type FranchiseeCreateNestedManyWithoutFranchisorInput = {
  connect?: InputMaybe<Array<FranchiseeWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<FranchiseeCreateOrConnectWithoutFranchisorInput>>;
  create?: InputMaybe<Array<FranchiseeCreateWithoutFranchisorInput>>;
  createMany?: InputMaybe<FranchiseeCreateManyFranchisorInputEnvelope>;
};

export type FranchiseeCreateNestedOneWithoutBusinessUnitsInput = {
  connect?: InputMaybe<FranchiseeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchiseeCreateOrConnectWithoutBusinessUnitsInput>;
  create?: InputMaybe<FranchiseeCreateWithoutBusinessUnitsInput>;
};

export type FranchiseeCreateNestedOneWithoutUserInput = {
  connect?: InputMaybe<FranchiseeWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchiseeCreateOrConnectWithoutUserInput>;
  create?: InputMaybe<FranchiseeCreateWithoutUserInput>;
};

export type FranchiseeCreateOrConnectWithoutBusinessUnitsInput = {
  create: FranchiseeCreateWithoutBusinessUnitsInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateOrConnectWithoutFranchisorInput = {
  create: FranchiseeCreateWithoutFranchisorInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateOrConnectWithoutUserInput = {
  create: FranchiseeCreateWithoutUserInput;
  where: FranchiseeWhereUniqueInput;
};

export type FranchiseeCreateWithoutBusinessUnitsInput = {
  User?: InputMaybe<UserCreateNestedManyWithoutFranchiseeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisor: FranchisorCreateNestedOneWithoutFranchiseesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeCreateWithoutFranchisorInput = {
  User?: InputMaybe<UserCreateNestedManyWithoutFranchiseeInput>;
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutFranchiseeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeCreateWithoutUserInput = {
  businessUnits?: InputMaybe<BusinessUnitCreateNestedManyWithoutFranchiseeInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisor: FranchisorCreateNestedOneWithoutFranchiseesInput;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchiseeListRelationFilter = {
  every?: InputMaybe<FranchiseeWhereInput>;
  none?: InputMaybe<FranchiseeWhereInput>;
  some?: InputMaybe<FranchiseeWhereInput>;
};

export type FranchiseeOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type FranchiseeOrderByWithRelationInput = {
  User?: InputMaybe<UserOrderByRelationAggregateInput>;
  businessUnits?: InputMaybe<BusinessUnitOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  franchisor?: InputMaybe<FranchisorOrderByWithRelationInput>;
  franchisorId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FranchiseeRelationFilter = {
  is?: InputMaybe<FranchiseeWhereInput>;
  isNot?: InputMaybe<FranchiseeWhereInput>;
};

export type FranchiseeWhereInput = {
  AND?: InputMaybe<Array<FranchiseeWhereInput>>;
  NOT?: InputMaybe<Array<FranchiseeWhereInput>>;
  OR?: InputMaybe<Array<FranchiseeWhereInput>>;
  User?: InputMaybe<UserListRelationFilter>;
  businessUnits?: InputMaybe<BusinessUnitListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisor?: InputMaybe<FranchisorRelationFilter>;
  franchisorId?: InputMaybe<IntFilter>;
  id?: InputMaybe<IntFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FranchiseeWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Franchisor = {
  __typename?: 'Franchisor';
  consultants: Array<User>;
  createdAt: Scalars['DateTime'];
  franchisees: Array<Franchisee>;
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type FranchisorCreateNestedOneWithoutConsultantsInput = {
  connect?: InputMaybe<FranchisorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchisorCreateOrConnectWithoutConsultantsInput>;
  create?: InputMaybe<FranchisorCreateWithoutConsultantsInput>;
};

export type FranchisorCreateNestedOneWithoutFranchiseesInput = {
  connect?: InputMaybe<FranchisorWhereUniqueInput>;
  connectOrCreate?: InputMaybe<FranchisorCreateOrConnectWithoutFranchiseesInput>;
  create?: InputMaybe<FranchisorCreateWithoutFranchiseesInput>;
};

export type FranchisorCreateOrConnectWithoutConsultantsInput = {
  create: FranchisorCreateWithoutConsultantsInput;
  where: FranchisorWhereUniqueInput;
};

export type FranchisorCreateOrConnectWithoutFranchiseesInput = {
  create: FranchisorCreateWithoutFranchiseesInput;
  where: FranchisorWhereUniqueInput;
};

export type FranchisorCreateWithoutConsultantsInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  franchisees?: InputMaybe<FranchiseeCreateNestedManyWithoutFranchisorInput>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchisorCreateWithoutFranchiseesInput = {
  consultants?: InputMaybe<UserCreateNestedManyWithoutFranchisorInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type FranchisorOrderByWithRelationInput = {
  consultants?: InputMaybe<UserOrderByRelationAggregateInput>;
  createdAt?: InputMaybe<SortOrder>;
  franchisees?: InputMaybe<FranchiseeOrderByRelationAggregateInput>;
  id?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  slug?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FranchisorRelationFilter = {
  is?: InputMaybe<FranchisorWhereInput>;
  isNot?: InputMaybe<FranchisorWhereInput>;
};

export type FranchisorWhereInput = {
  AND?: InputMaybe<Array<FranchisorWhereInput>>;
  NOT?: InputMaybe<Array<FranchisorWhereInput>>;
  OR?: InputMaybe<Array<FranchisorWhereInput>>;
  consultants?: InputMaybe<UserListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  franchisees?: InputMaybe<FranchiseeListRelationFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
  slug?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FranchisorWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type InventoryItem = {
  __typename?: 'InventoryItem';
  businessUnit: BusinessUnit;
  businessUnitId: Scalars['Int'];
  categories: Array<InventoryItemCategory>;
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  place: Place;
  positionIndex: Scalars['Int'];
  priceHistories: Array<PriceHistory>;
  quantity: Scalars['Float'];
  stockEntries: Array<StockEntry>;
  stockUpdates: Array<StockUpdate>;
  supplier?: Maybe<Supplier>;
  supplierId?: Maybe<Scalars['Int']>;
  unitOfMeasure?: Maybe<UnitOfMeasure>;
  unitPrice: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryItemCategory = {
  __typename?: 'InventoryItemCategory';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type InventoryItemCategoryCreateNestedManyWithoutInventoryItemsInput = {
  connect?: InputMaybe<Array<InventoryItemCategoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCategoryCreateOrConnectWithoutInventoryItemsInput>>;
  create?: InputMaybe<Array<InventoryItemCategoryCreateWithoutInventoryItemsInput>>;
};

export type InventoryItemCategoryCreateOrConnectWithoutInventoryItemsInput = {
  create: InventoryItemCategoryCreateWithoutInventoryItemsInput;
  where: InventoryItemCategoryWhereUniqueInput;
};

export type InventoryItemCategoryCreateWithoutInventoryItemsInput = {
  name: Scalars['String'];
};

export type InventoryItemCategoryInput = {
  name: Scalars['String'];
};

export type InventoryItemCategoryListRelationFilter = {
  every?: InputMaybe<InventoryItemCategoryWhereInput>;
  none?: InputMaybe<InventoryItemCategoryWhereInput>;
  some?: InputMaybe<InventoryItemCategoryWhereInput>;
};

export type InventoryItemCategoryWhereInput = {
  AND?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  NOT?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  OR?: InputMaybe<Array<InventoryItemCategoryWhereInput>>;
  id?: InputMaybe<IntFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
};

export type InventoryItemCategoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type InventoryItemCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  place: Place;
  positionIndex: Scalars['Int'];
  quantity: Scalars['Float'];
  supplierId?: InputMaybe<Scalars['Int']>;
  unitOfMeasureId?: InputMaybe<Scalars['Int']>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemCreateManyBusinessUnitInputEnvelope = {
  data: Array<InventoryItemCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type InventoryItemCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<InventoryItemWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<InventoryItemCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<InventoryItemCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<InventoryItemCreateManyBusinessUnitInputEnvelope>;
};

export type InventoryItemCreateOrConnectWithoutBusinessUnitInput = {
  create: InventoryItemCreateWithoutBusinessUnitInput;
  where: InventoryItemWhereUniqueInput;
};

export type InventoryItemCreateWithoutBusinessUnitInput = {
  categories?: InputMaybe<InventoryItemCategoryCreateNestedManyWithoutInventoryItemsInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  place: Place;
  positionIndex: Scalars['Int'];
  priceHistories?: InputMaybe<PriceHistoryCreateNestedManyWithoutInventoryItemInput>;
  quantity: Scalars['Float'];
  stockEntries?: InputMaybe<StockEntryCreateNestedManyWithoutInventoryItemInput>;
  stockUpdates?: InputMaybe<StockUpdateCreateNestedManyWithoutInventoryItemInput>;
  supplier?: InputMaybe<SupplierCreateNestedOneWithoutInventoryItemsInput>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureCreateNestedOneWithoutInventoryItemInput>;
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryItemListRelationFilter = {
  every?: InputMaybe<InventoryItemWhereInput>;
  none?: InputMaybe<InventoryItemWhereInput>;
  some?: InputMaybe<InventoryItemWhereInput>;
};

export type InventoryItemOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type InventoryItemRelationFilter = {
  is?: InputMaybe<InventoryItemWhereInput>;
  isNot?: InputMaybe<InventoryItemWhereInput>;
};

export type InventoryItemWhereInput = {
  AND?: InputMaybe<Array<InventoryItemWhereInput>>;
  NOT?: InputMaybe<Array<InventoryItemWhereInput>>;
  OR?: InputMaybe<Array<InventoryItemWhereInput>>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntFilter>;
  categories?: InputMaybe<InventoryItemCategoryListRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  minimumQuantity?: InputMaybe<FloatFilter>;
  name?: InputMaybe<StringFilter>;
  place?: InputMaybe<EnumPlaceFilter>;
  positionIndex?: InputMaybe<IntFilter>;
  priceHistories?: InputMaybe<PriceHistoryListRelationFilter>;
  quantity?: InputMaybe<FloatFilter>;
  stockEntries?: InputMaybe<StockEntryListRelationFilter>;
  stockUpdates?: InputMaybe<StockUpdateListRelationFilter>;
  supplier?: InputMaybe<SupplierRelationFilter>;
  supplierId?: InputMaybe<IntNullableFilter>;
  unitOfMeasure?: InputMaybe<UnitOfMeasureRelationFilter>;
  unitOfMeasureId?: InputMaybe<IntNullableFilter>;
  unitPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type InventoryItemWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type LoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  closeInventory: Array<InventoryItem>;
  createActivityLog: ActivityLog;
  createBusinessUnit: BusinessUnit;
  createInventoryItem: InventoryItem;
  createItemCategory: InventoryItemCategory;
  createStockUpdate: StockUpdate;
  createUser: User;
  deleteInventoryItem?: Maybe<InventoryItem>;
  destroyAccount: Scalars['Boolean'];
  forgotPassword: Scalars['Boolean'];
  getBulkSignedS3UrlForPut?: Maybe<Array<SignedResponse>>;
  getSignedS3UrlForPut?: Maybe<SignedResponse>;
  login: AuthResponse;
  register: AuthResponse;
  resetPassword: Scalars['Boolean'];
  updateInventoryItem: InventoryItem;
  updateInventoryItemsOrder: Array<InventoryItem>;
  updateMe: User;
};


export type MutationCloseInventoryArgs = {
  data: CloseInventoryInput;
};


export type MutationCreateActivityLogArgs = {
  data: ActivityLogCreateInput;
};


export type MutationCreateBusinessUnitArgs = {
  data: BusinessCreateInput;
};


export type MutationCreateInventoryItemArgs = {
  data: CreateInventoryItemInput;
};


export type MutationCreateItemCategoryArgs = {
  data: InventoryItemCategoryInput;
};


export type MutationCreateStockUpdateArgs = {
  data: CreateStockUpdateInput;
};


export type MutationCreateUserArgs = {
  data: UserCreateInput;
};


export type MutationDeleteInventoryItemArgs = {
  id: Scalars['Int'];
};


export type MutationForgotPasswordArgs = {
  email: Scalars['String'];
};


export type MutationGetBulkSignedS3UrlForPutArgs = {
  data: S3BulkSignedUrlInput;
};


export type MutationGetSignedS3UrlForPutArgs = {
  data: S3SignedUrlInput;
};


export type MutationLoginArgs = {
  data: LoginInput;
};


export type MutationRegisterArgs = {
  data: RegisterInput;
};


export type MutationResetPasswordArgs = {
  data: ResetPasswordInput;
};


export type MutationUpdateInventoryItemArgs = {
  data: UpdateInventoryItemDataInput;
};


export type MutationUpdateInventoryItemsOrderArgs = {
  items: Array<UpdateInventoryItemInput>;
};


export type MutationUpdateMeArgs = {
  data: UpdateUserInput;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']>;
  gt?: InputMaybe<Scalars['DateTime']>;
  gte?: InputMaybe<Scalars['DateTime']>;
  in?: InputMaybe<Array<Scalars['DateTime']>>;
  lt?: InputMaybe<Scalars['DateTime']>;
  lte?: InputMaybe<Scalars['DateTime']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']>>;
};

export type NestedEnumPlaceFilter = {
  equals?: InputMaybe<Place>;
  in?: InputMaybe<Array<Place>>;
  not?: InputMaybe<NestedEnumPlaceFilter>;
  notIn?: InputMaybe<Array<Place>>;
};

export type NestedEnumRoleFilter = {
  equals?: InputMaybe<Role>;
  in?: InputMaybe<Array<Role>>;
  not?: InputMaybe<NestedEnumRoleFilter>;
  notIn?: InputMaybe<Array<Role>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export enum Place {
  Kitchen = 'KITCHEN',
  Saloon = 'SALOON'
}

export type PriceHistory = {
  __typename?: 'PriceHistory';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem?: Maybe<InventoryItem>;
  inventoryItemId?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type PriceHistoryCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PriceHistoryCreateManyInventoryItemInputEnvelope = {
  data: Array<PriceHistoryCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type PriceHistoryCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<PriceHistoryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<PriceHistoryCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<PriceHistoryCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<PriceHistoryCreateManyInventoryItemInputEnvelope>;
};

export type PriceHistoryCreateOrConnectWithoutInventoryItemInput = {
  create: PriceHistoryCreateWithoutInventoryItemInput;
  where: PriceHistoryWhereUniqueInput;
};

export type PriceHistoryCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  price: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type PriceHistoryListRelationFilter = {
  every?: InputMaybe<PriceHistoryWhereInput>;
  none?: InputMaybe<PriceHistoryWhereInput>;
  some?: InputMaybe<PriceHistoryWhereInput>;
};

export type PriceHistoryWhereInput = {
  AND?: InputMaybe<Array<PriceHistoryWhereInput>>;
  NOT?: InputMaybe<Array<PriceHistoryWhereInput>>;
  OR?: InputMaybe<Array<PriceHistoryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  price?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PriceHistoryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type Query = {
  __typename?: 'Query';
  getActivityLogs: Array<ActivityLog>;
  getAllFranchisors: Array<Franchisor>;
  getAllInventoryItems: Array<InventoryItem>;
  getAllItemCategories: Array<InventoryItemCategory>;
  getAllUnitOfMeasures: Array<UnitOfMeasure>;
  getBusinessUnitBySlug?: Maybe<BusinessUnit>;
  getBusinessUnits: Array<BusinessUnit>;
  getFranchiseeById?: Maybe<Franchisee>;
  getFranchisees: Array<Franchisee>;
  getFranchisorById?: Maybe<Franchisor>;
  getFranchisorBySlug?: Maybe<Franchisor>;
  getInventoryItemById?: Maybe<InventoryItem>;
  getInventoryItemsByBusinessUnit: Array<InventoryItem>;
  getSignedS3UrlForGet?: Maybe<Scalars['String']>;
  getStockUpdateByInventoryItemId: Array<StockUpdate>;
  me?: Maybe<User>;
  priceHistories: Array<PriceHistory>;
  priceHistory?: Maybe<PriceHistory>;
  refreshToken?: Maybe<RefreshTokenResponse>;
  stockEntries: Array<StockEntry>;
  stockEntry?: Maybe<StockEntry>;
  stockUpdates: Array<StockUpdate>;
  supplier?: Maybe<Supplier>;
  suppliers: Array<Supplier>;
  user?: Maybe<User>;
  users: UsersResponse;
};


export type QueryGetActivityLogsArgs = {
  userId: Scalars['Int'];
};


export type QueryGetBusinessUnitBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetFranchiseeByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetFranchisorByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetFranchisorBySlugArgs = {
  slug: Scalars['String'];
};


export type QueryGetInventoryItemByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetInventoryItemsByBusinessUnitArgs = {
  businessUnitId: Scalars['Int'];
  place?: InputMaybe<Place>;
};


export type QueryGetSignedS3UrlForGetArgs = {
  key: Scalars['String'];
};


export type QueryGetStockUpdateByInventoryItemIdArgs = {
  endDate?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
};


export type QueryPriceHistoryArgs = {
  id: Scalars['Int'];
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String'];
};


export type QueryStockEntryArgs = {
  id: Scalars['Int'];
};


export type QuerySupplierArgs = {
  id: Scalars['Int'];
};


export type QueryUserArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};


export type QueryUsersArgs = {
  cursor?: InputMaybe<UserWhereUniqueInput>;
  distinct?: InputMaybe<Array<UserScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<UserOrderByWithRelationInput>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  where?: InputMaybe<UserWhereInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type RefreshTokenResponse = {
  __typename?: 'RefreshTokenResponse';
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type RegisterInput = {
  businessUnitId: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId: Scalars['Float'];
  franchisorId: Scalars['Float'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
};

export type ResetPasswordInput = {
  password: Scalars['String'];
  token: Scalars['String'];
};

export enum Role {
  Employee = 'EMPLOYEE',
  Finance = 'FINANCE',
  Franchisee = 'FRANCHISEE',
  Franchisor = 'FRANCHISOR',
  KitchenStaff = 'KITCHEN_STAFF',
  SaloonStaff = 'SALOON_STAFF',
  UnitManager = 'UNIT_MANAGER'
}

export type S3BulkSignedUrlInput = {
  files: Array<S3SignedUrlInput>;
};

export type S3SignedUrlInput = {
  fileType: Scalars['String'];
  key: Scalars['String'];
};

export type SignedResponse = {
  __typename?: 'SignedResponse';
  key: Scalars['String'];
  uploadUrl: Scalars['String'];
  url: Scalars['String'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type StockEntry = {
  __typename?: 'StockEntry';
  createdAt: Scalars['DateTime'];
  date: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['Float'];
  quantity: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type StockEntryCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StockEntryCreateManyInventoryItemInputEnvelope = {
  data: Array<StockEntryCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StockEntryCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<StockEntryWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StockEntryCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<StockEntryCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<StockEntryCreateManyInventoryItemInputEnvelope>;
};

export type StockEntryCreateOrConnectWithoutInventoryItemInput = {
  create: StockEntryCreateWithoutInventoryItemInput;
  where: StockEntryWhereUniqueInput;
};

export type StockEntryCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  date?: InputMaybe<Scalars['DateTime']>;
  quantity: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StockEntryListRelationFilter = {
  every?: InputMaybe<StockEntryWhereInput>;
  none?: InputMaybe<StockEntryWhereInput>;
  some?: InputMaybe<StockEntryWhereInput>;
};

export type StockEntryWhereInput = {
  AND?: InputMaybe<Array<StockEntryWhereInput>>;
  NOT?: InputMaybe<Array<StockEntryWhereInput>>;
  OR?: InputMaybe<Array<StockEntryWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  date?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  quantity?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StockEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type StockUpdate = {
  __typename?: 'StockUpdate';
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  inventoryItem: InventoryItem;
  inventoryItemId: Scalars['Float'];
  observation?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  updatedAt: Scalars['DateTime'];
};

export type StockUpdateCreateManyInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  observation?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StockUpdateCreateManyInventoryItemInputEnvelope = {
  data: Array<StockUpdateCreateManyInventoryItemInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type StockUpdateCreateNestedManyWithoutInventoryItemInput = {
  connect?: InputMaybe<Array<StockUpdateWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<StockUpdateCreateOrConnectWithoutInventoryItemInput>>;
  create?: InputMaybe<Array<StockUpdateCreateWithoutInventoryItemInput>>;
  createMany?: InputMaybe<StockUpdateCreateManyInventoryItemInputEnvelope>;
};

export type StockUpdateCreateOrConnectWithoutInventoryItemInput = {
  create: StockUpdateCreateWithoutInventoryItemInput;
  where: StockUpdateWhereUniqueInput;
};

export type StockUpdateCreateWithoutInventoryItemInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  observation?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type StockUpdateListRelationFilter = {
  every?: InputMaybe<StockUpdateWhereInput>;
  none?: InputMaybe<StockUpdateWhereInput>;
  some?: InputMaybe<StockUpdateWhereInput>;
};

export type StockUpdateWhereInput = {
  AND?: InputMaybe<Array<StockUpdateWhereInput>>;
  NOT?: InputMaybe<Array<StockUpdateWhereInput>>;
  OR?: InputMaybe<Array<StockUpdateWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItem?: InputMaybe<InventoryItemRelationFilter>;
  inventoryItemId?: InputMaybe<IntFilter>;
  observation?: InputMaybe<StringNullableFilter>;
  quantity?: InputMaybe<FloatFilter>;
  unitPrice?: InputMaybe<FloatFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type StockUpdateWhereUniqueInput = {
  id?: InputMaybe<Scalars['Int']>;
};

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']>;
  endsWith?: InputMaybe<Scalars['String']>;
  equals?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  gte?: InputMaybe<Scalars['String']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  lt?: InputMaybe<Scalars['String']>;
  lte?: InputMaybe<Scalars['String']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']>>;
  startsWith?: InputMaybe<Scalars['String']>;
};

export type Supplier = {
  __typename?: 'Supplier';
  address?: Maybe<Scalars['String']>;
  contact?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  id: Scalars['Float'];
  inventoryItems?: Maybe<Array<InventoryItem>>;
  name: Scalars['String'];
  phone?: Maybe<Scalars['String']>;
  updatedAt: Scalars['DateTime'];
};

export type SupplierCreateNestedOneWithoutInventoryItemsInput = {
  connect?: InputMaybe<SupplierWhereUniqueInput>;
  connectOrCreate?: InputMaybe<SupplierCreateOrConnectWithoutInventoryItemsInput>;
  create?: InputMaybe<SupplierCreateWithoutInventoryItemsInput>;
};

export type SupplierCreateOrConnectWithoutInventoryItemsInput = {
  create: SupplierCreateWithoutInventoryItemsInput;
  where: SupplierWhereUniqueInput;
};

export type SupplierCreateWithoutInventoryItemsInput = {
  address?: InputMaybe<Scalars['String']>;
  contact?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierRelationFilter = {
  is?: InputMaybe<SupplierWhereInput>;
  isNot?: InputMaybe<SupplierWhereInput>;
};

export type SupplierWhereInput = {
  AND?: InputMaybe<Array<SupplierWhereInput>>;
  NOT?: InputMaybe<Array<SupplierWhereInput>>;
  OR?: InputMaybe<Array<SupplierWhereInput>>;
  address?: InputMaybe<StringNullableFilter>;
  contact?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<IntFilter>;
  inventoryItems?: InputMaybe<InventoryItemListRelationFilter>;
  name?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type SupplierWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  abbreviation: Scalars['String'];
  createdAt: Scalars['DateTime'];
  id: Scalars['Float'];
  name: Scalars['String'];
  updatedAt: Scalars['DateTime'];
};

export type UnitOfMeasureCreateNestedOneWithoutInventoryItemInput = {
  connect?: InputMaybe<UnitOfMeasureWhereUniqueInput>;
  connectOrCreate?: InputMaybe<UnitOfMeasureCreateOrConnectWithoutInventoryItemInput>;
  create?: InputMaybe<UnitOfMeasureCreateWithoutInventoryItemInput>;
};

export type UnitOfMeasureCreateOrConnectWithoutInventoryItemInput = {
  create: UnitOfMeasureCreateWithoutInventoryItemInput;
  where: UnitOfMeasureWhereUniqueInput;
};

export type UnitOfMeasureCreateWithoutInventoryItemInput = {
  abbreviation: Scalars['String'];
  name: Scalars['String'];
};

export type UnitOfMeasureRelationFilter = {
  is?: InputMaybe<UnitOfMeasureWhereInput>;
  isNot?: InputMaybe<UnitOfMeasureWhereInput>;
};

export type UnitOfMeasureWhereInput = {
  AND?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  InventoryItem?: InputMaybe<InventoryItemListRelationFilter>;
  NOT?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  OR?: InputMaybe<Array<UnitOfMeasureWhereInput>>;
  abbreviation?: InputMaybe<StringFilter>;
  id?: InputMaybe<IntFilter>;
  name?: InputMaybe<StringFilter>;
};

export type UnitOfMeasureWhereUniqueInput = {
  abbreviation?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateInventoryItemDataInput = {
  businessUnitId: Scalars['Float'];
  categories: Array<CategoryInput>;
  id: Scalars['Int'];
  minimumQuantity: Scalars['Float'];
  name: Scalars['String'];
  place: Place;
  positionIndex: Scalars['Float'];
  quantity: Scalars['Float'];
  unitOfMeasureId: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type UpdateInventoryItemInput = {
  id: Scalars['Int'];
  newOrderPosition: Scalars['Int'];
};

export type UpdateInventoryQuantityInput = {
  id: Scalars['Float'];
  observation: Scalars['String'];
  quantity: Scalars['Float'];
  unitPrice: Scalars['Float'];
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  businessUnit?: Maybe<BusinessUnit>;
  businessUnitId?: Maybe<Scalars['Int']>;
  createdAt: Scalars['DateTime'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: Maybe<Franchisee>;
  franchiseeId?: Maybe<Scalars['Int']>;
  franchisor?: Maybe<Franchisor>;
  franchisorId?: Maybe<Scalars['Int']>;
  fullName: Scalars['String'];
  id: Scalars['Float'];
  lastName: Scalars['String'];
  role: Role;
  updatedAt: Scalars['DateTime'];
};

export type UserCreateInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUserInput>;
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyBusinessUnitInput = {
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId?: InputMaybe<Scalars['Int']>;
  franchisorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyBusinessUnitInputEnvelope = {
  data: Array<UserCreateManyBusinessUnitInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyFranchiseeInput = {
  businessUnitId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisorId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyFranchiseeInputEnvelope = {
  data: Array<UserCreateManyFranchiseeInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateManyFranchisorInput = {
  businessUnitId?: InputMaybe<Scalars['Int']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchiseeId?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateManyFranchisorInputEnvelope = {
  data: Array<UserCreateManyFranchisorInput>;
  skipDuplicates?: InputMaybe<Scalars['Boolean']>;
};

export type UserCreateNestedManyWithoutBusinessUnitInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutBusinessUnitInput>>;
  create?: InputMaybe<Array<UserCreateWithoutBusinessUnitInput>>;
  createMany?: InputMaybe<UserCreateManyBusinessUnitInputEnvelope>;
};

export type UserCreateNestedManyWithoutFranchiseeInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFranchiseeInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFranchiseeInput>>;
  createMany?: InputMaybe<UserCreateManyFranchiseeInputEnvelope>;
};

export type UserCreateNestedManyWithoutFranchisorInput = {
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
  connectOrCreate?: InputMaybe<Array<UserCreateOrConnectWithoutFranchisorInput>>;
  create?: InputMaybe<Array<UserCreateWithoutFranchisorInput>>;
  createMany?: InputMaybe<UserCreateManyFranchisorInputEnvelope>;
};

export type UserCreateOrConnectWithoutBusinessUnitInput = {
  create: UserCreateWithoutBusinessUnitInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFranchiseeInput = {
  create: UserCreateWithoutFranchiseeInput;
  where: UserWhereUniqueInput;
};

export type UserCreateOrConnectWithoutFranchisorInput = {
  create: UserCreateWithoutFranchisorInput;
  where: UserWhereUniqueInput;
};

export type UserCreateWithoutBusinessUnitInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUserInput>;
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFranchiseeInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisor?: InputMaybe<FranchisorCreateNestedOneWithoutConsultantsInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserCreateWithoutFranchisorInput = {
  activityLogs?: InputMaybe<ActivityLogCreateNestedManyWithoutUserInput>;
  businessUnit?: InputMaybe<BusinessUnitCreateNestedOneWithoutUsersInput>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  franchisee?: InputMaybe<FranchiseeCreateNestedOneWithoutUserInput>;
  lastName: Scalars['String'];
  password: Scalars['String'];
  role: Role;
  updatedAt?: InputMaybe<Scalars['DateTime']>;
};

export type UserListRelationFilter = {
  every?: InputMaybe<UserWhereInput>;
  none?: InputMaybe<UserWhereInput>;
  some?: InputMaybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: InputMaybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  activityLogs?: InputMaybe<ActivityLogOrderByRelationAggregateInput>;
  businessUnit?: InputMaybe<BusinessUnitOrderByWithRelationInput>;
  businessUnitId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  franchisee?: InputMaybe<FranchiseeOrderByWithRelationInput>;
  franchiseeId?: InputMaybe<SortOrder>;
  franchisor?: InputMaybe<FranchisorOrderByWithRelationInput>;
  franchisorId?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
  password?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type UserRelationFilter = {
  is?: InputMaybe<UserWhereInput>;
  isNot?: InputMaybe<UserWhereInput>;
};

export enum UserScalarFieldEnum {
  BusinessUnitId = 'businessUnitId',
  CreatedAt = 'createdAt',
  Email = 'email',
  FirstName = 'firstName',
  FranchiseeId = 'franchiseeId',
  FranchisorId = 'franchisorId',
  Id = 'id',
  LastName = 'lastName',
  Password = 'password',
  Role = 'role',
  UpdatedAt = 'updatedAt'
}

export type UserWhereInput = {
  AND?: InputMaybe<Array<UserWhereInput>>;
  NOT?: InputMaybe<Array<UserWhereInput>>;
  OR?: InputMaybe<Array<UserWhereInput>>;
  activityLogs?: InputMaybe<ActivityLogListRelationFilter>;
  businessUnit?: InputMaybe<BusinessUnitRelationFilter>;
  businessUnitId?: InputMaybe<IntNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  email?: InputMaybe<StringFilter>;
  firstName?: InputMaybe<StringFilter>;
  franchisee?: InputMaybe<FranchiseeRelationFilter>;
  franchiseeId?: InputMaybe<IntNullableFilter>;
  franchisor?: InputMaybe<FranchisorRelationFilter>;
  franchisorId?: InputMaybe<IntNullableFilter>;
  id?: InputMaybe<IntFilter>;
  lastName?: InputMaybe<StringFilter>;
  password?: InputMaybe<StringFilter>;
  role?: InputMaybe<EnumRoleFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type UserWhereUniqueInput = {
  email?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
};

export type UsersResponse = {
  __typename?: 'UsersResponse';
  count: Scalars['Int'];
  items: Array<User>;
};

export type AdminCreateUserMutationVariables = Exact<{
  data: UserCreateInput;
}>;


export type AdminCreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'User', id: number } };

export type GetFranchisorBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetFranchisorBySlugQuery = { __typename?: 'Query', getFranchisorBySlug?: { __typename?: 'Franchisor', id: number, name: string } | null };

export type GetAllItemCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllItemCategoriesQuery = { __typename?: 'Query', getAllItemCategories: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string }> };

export type GetAllUnitOfMeasuresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllUnitOfMeasuresQuery = { __typename?: 'Query', getAllUnitOfMeasures: Array<{ __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string }> };

export type CreateInventoryItemMutationVariables = Exact<{
  data: CreateInventoryItemInput;
}>;


export type CreateInventoryItemMutation = { __typename?: 'Mutation', createInventoryItem: { __typename?: 'InventoryItem', id: number, place: Place, name: string, unitPrice: number, minimumQuantity: number, quantity: number, positionIndex: number, updatedAt: string, categories: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string }>, unitOfMeasure?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, stockUpdates: Array<{ __typename?: 'StockUpdate', observation?: string | null, quantity: number, createdAt: string }> } };

export type UpdateInventoryItemMutationVariables = Exact<{
  data: UpdateInventoryItemDataInput;
}>;


export type UpdateInventoryItemMutation = { __typename?: 'Mutation', updateInventoryItem: { __typename?: 'InventoryItem', id: number, name: string } };

export type MeFragment = { __typename?: 'User', id: number, firstName: string, lastName: string, fullName: string, businessUnitId?: number | null, franchiseeId?: number | null, email: string, role: Role };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: number, firstName: string, lastName: string, fullName: string, businessUnitId?: number | null, franchiseeId?: number | null, email: string, role: Role } | null };

export type GetSignedUrlForPutMutationVariables = Exact<{
  data: S3SignedUrlInput;
}>;


export type GetSignedUrlForPutMutation = { __typename?: 'Mutation', getSignedS3UrlForPut?: { __typename?: 'SignedResponse', url: string, uploadUrl: string } | null };

export type GetBulkSignedUrlForPutMutationVariables = Exact<{
  data: S3BulkSignedUrlInput;
}>;


export type GetBulkSignedUrlForPutMutation = { __typename?: 'Mutation', getBulkSignedS3UrlForPut?: Array<{ __typename?: 'SignedResponse', url: string, uploadUrl: string, key: string }> | null };

export type UpdateInventoryItemsOrderMutationVariables = Exact<{
  items: Array<UpdateInventoryItemInput> | UpdateInventoryItemInput;
}>;


export type UpdateInventoryItemsOrderMutation = { __typename?: 'Mutation', updateInventoryItemsOrder: Array<{ __typename?: 'InventoryItem', id: number, positionIndex: number }> };

export type GetBusinessUnitBySlugQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetBusinessUnitBySlugQuery = { __typename?: 'Query', getBusinessUnitBySlug?: { __typename?: 'BusinessUnit', id: number, name: string } | null };

export type InventoryItemsByBusinessQueryVariables = Exact<{
  id: Scalars['Int'];
  place?: InputMaybe<Place>;
}>;


export type InventoryItemsByBusinessQuery = { __typename?: 'Query', getInventoryItemsByBusinessUnit: Array<{ __typename?: 'InventoryItem', id: number, place: Place, name: string, unitPrice: number, minimumQuantity: number, quantity: number, positionIndex: number, updatedAt: string, categories: Array<{ __typename?: 'InventoryItemCategory', id: number, name: string }>, unitOfMeasure?: { __typename?: 'UnitOfMeasure', id: number, name: string, abbreviation: string } | null, stockUpdates: Array<{ __typename?: 'StockUpdate', observation?: string | null, quantity: number, createdAt: string }> }> };

export type CloseInventoryMutationVariables = Exact<{
  data: CloseInventoryInput;
}>;


export type CloseInventoryMutation = { __typename?: 'Mutation', closeInventory: Array<{ __typename?: 'InventoryItem', id: number, name: string }> };

export type GetStockUpdateByInventoryItemIdQueryVariables = Exact<{
  id: Scalars['Int'];
  startDate?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
}>;


export type GetStockUpdateByInventoryItemIdQuery = { __typename?: 'Query', getStockUpdateByInventoryItemId: Array<{ __typename?: 'StockUpdate', id: number, quantity: number, unitPrice: number, observation?: string | null, updatedAt: string }> };

export type DeleteInventoryItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteInventoryItemMutation = { __typename?: 'Mutation', deleteInventoryItem?: { __typename?: 'InventoryItem', id: number } | null };

export type RefreshTokenQueryVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type RefreshTokenQuery = { __typename?: 'Query', refreshToken?: { __typename?: 'RefreshTokenResponse', token: string, refreshToken: string } | null };

export type ForgotPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordMutation = { __typename?: 'Mutation', forgotPassword: boolean };

export type LoginMutationVariables = Exact<{
  data: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthResponse', token: string, refreshToken: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string, fullName: string, businessUnitId?: number | null, franchiseeId?: number | null, email: string, role: Role, businessUnit?: { __typename?: 'BusinessUnit', name: string, slug: string } | null } } };

export type UpdateMeMutationVariables = Exact<{
  data: UpdateUserInput;
}>;


export type UpdateMeMutation = { __typename?: 'Mutation', updateMe: { __typename?: 'User', id: number, firstName: string, lastName: string, fullName: string, businessUnitId?: number | null, franchiseeId?: number | null, email: string, role: Role } };

export type DestroyAccountMutationVariables = Exact<{ [key: string]: never; }>;


export type DestroyAccountMutation = { __typename?: 'Mutation', destroyAccount: boolean };

export type RegisterMutationVariables = Exact<{
  data: RegisterInput;
}>;


export type RegisterMutation = { __typename?: 'Mutation', register: { __typename?: 'AuthResponse', token: string, refreshToken: string, user: { __typename?: 'User', id: number, firstName: string, lastName: string, fullName: string, businessUnitId?: number | null, franchiseeId?: number | null, email: string, role: Role } } };

export type ResetPasswordMutationVariables = Exact<{
  data: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'Mutation', resetPassword: boolean };

export const MeFragmentDoc = gql`
    fragment Me on User {
  id
  firstName
  lastName
  fullName
  businessUnitId
  franchiseeId
  email
  role
}
    `;
export const AdminCreateUserDocument = gql`
    mutation AdminCreateUser($data: UserCreateInput!) {
  createUser(data: $data) {
    id
  }
}
    `;
export function useAdminCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<AdminCreateUserMutation, AdminCreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminCreateUserMutation, AdminCreateUserMutationVariables>(AdminCreateUserDocument, options);
      }
export type AdminCreateUserMutationHookResult = ReturnType<typeof useAdminCreateUserMutation>;
export type AdminCreateUserMutationResult = Apollo.MutationResult<AdminCreateUserMutation>;
export type AdminCreateUserMutationOptions = Apollo.BaseMutationOptions<AdminCreateUserMutation, AdminCreateUserMutationVariables>;
export const GetFranchisorBySlugDocument = gql`
    query GetFranchisorBySlug($slug: String!) {
  getFranchisorBySlug(slug: $slug) {
    id
    name
  }
}
    `;
export function useGetFranchisorBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>(GetFranchisorBySlugDocument, options);
      }
export function useGetFranchisorBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>(GetFranchisorBySlugDocument, options);
        }
export type GetFranchisorBySlugQueryHookResult = ReturnType<typeof useGetFranchisorBySlugQuery>;
export type GetFranchisorBySlugLazyQueryHookResult = ReturnType<typeof useGetFranchisorBySlugLazyQuery>;
export type GetFranchisorBySlugQueryResult = Apollo.QueryResult<GetFranchisorBySlugQuery, GetFranchisorBySlugQueryVariables>;
export const GetAllItemCategoriesDocument = gql`
    query GetAllItemCategories {
  getAllItemCategories {
    id
    name
  }
}
    `;
export function useGetAllItemCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
      }
export function useGetAllItemCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>(GetAllItemCategoriesDocument, options);
        }
export type GetAllItemCategoriesQueryHookResult = ReturnType<typeof useGetAllItemCategoriesQuery>;
export type GetAllItemCategoriesLazyQueryHookResult = ReturnType<typeof useGetAllItemCategoriesLazyQuery>;
export type GetAllItemCategoriesQueryResult = Apollo.QueryResult<GetAllItemCategoriesQuery, GetAllItemCategoriesQueryVariables>;
export const GetAllUnitOfMeasuresDocument = gql`
    query GetAllUnitOfMeasures {
  getAllUnitOfMeasures {
    id
    name
    abbreviation
  }
}
    `;
export function useGetAllUnitOfMeasuresQuery(baseOptions?: Apollo.QueryHookOptions<GetAllUnitOfMeasuresQuery, GetAllUnitOfMeasuresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllUnitOfMeasuresQuery, GetAllUnitOfMeasuresQueryVariables>(GetAllUnitOfMeasuresDocument, options);
      }
export function useGetAllUnitOfMeasuresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllUnitOfMeasuresQuery, GetAllUnitOfMeasuresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllUnitOfMeasuresQuery, GetAllUnitOfMeasuresQueryVariables>(GetAllUnitOfMeasuresDocument, options);
        }
export type GetAllUnitOfMeasuresQueryHookResult = ReturnType<typeof useGetAllUnitOfMeasuresQuery>;
export type GetAllUnitOfMeasuresLazyQueryHookResult = ReturnType<typeof useGetAllUnitOfMeasuresLazyQuery>;
export type GetAllUnitOfMeasuresQueryResult = Apollo.QueryResult<GetAllUnitOfMeasuresQuery, GetAllUnitOfMeasuresQueryVariables>;
export const CreateInventoryItemDocument = gql`
    mutation CreateInventoryItem($data: CreateInventoryItemInput!) {
  createInventoryItem(data: $data) {
    id
    place
    name
    unitPrice
    minimumQuantity
    quantity
    positionIndex
    categories {
      id
      name
    }
    unitOfMeasure {
      id
      name
      abbreviation
    }
    stockUpdates {
      observation
      quantity
      createdAt
    }
    updatedAt
  }
}
    `;
export function useCreateInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>(CreateInventoryItemDocument, options);
      }
export type CreateInventoryItemMutationHookResult = ReturnType<typeof useCreateInventoryItemMutation>;
export type CreateInventoryItemMutationResult = Apollo.MutationResult<CreateInventoryItemMutation>;
export type CreateInventoryItemMutationOptions = Apollo.BaseMutationOptions<CreateInventoryItemMutation, CreateInventoryItemMutationVariables>;
export const UpdateInventoryItemDocument = gql`
    mutation UpdateInventoryItem($data: UpdateInventoryItemDataInput!) {
  updateInventoryItem(data: $data) {
    id
    name
  }
}
    `;
export function useUpdateInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>(UpdateInventoryItemDocument, options);
      }
export type UpdateInventoryItemMutationHookResult = ReturnType<typeof useUpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationResult = Apollo.MutationResult<UpdateInventoryItemMutation>;
export type UpdateInventoryItemMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryItemMutation, UpdateInventoryItemMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const GetSignedUrlForPutDocument = gql`
    mutation GetSignedUrlForPut($data: S3SignedUrlInput!) {
  getSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
  }
}
    `;
export function useGetSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>(GetSignedUrlForPutDocument, options);
      }
export type GetSignedUrlForPutMutationHookResult = ReturnType<typeof useGetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationResult = Apollo.MutationResult<GetSignedUrlForPutMutation>;
export type GetSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetSignedUrlForPutMutation, GetSignedUrlForPutMutationVariables>;
export const GetBulkSignedUrlForPutDocument = gql`
    mutation GetBulkSignedUrlForPut($data: S3BulkSignedUrlInput!) {
  getBulkSignedS3UrlForPut(data: $data) {
    url
    uploadUrl
    key
  }
}
    `;
export function useGetBulkSignedUrlForPutMutation(baseOptions?: Apollo.MutationHookOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>(GetBulkSignedUrlForPutDocument, options);
      }
export type GetBulkSignedUrlForPutMutationHookResult = ReturnType<typeof useGetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationResult = Apollo.MutationResult<GetBulkSignedUrlForPutMutation>;
export type GetBulkSignedUrlForPutMutationOptions = Apollo.BaseMutationOptions<GetBulkSignedUrlForPutMutation, GetBulkSignedUrlForPutMutationVariables>;
export const UpdateInventoryItemsOrderDocument = gql`
    mutation UpdateInventoryItemsOrder($items: [UpdateInventoryItemInput!]!) {
  updateInventoryItemsOrder(items: $items) {
    id
    positionIndex
  }
}
    `;
export function useUpdateInventoryItemsOrderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInventoryItemsOrderMutation, UpdateInventoryItemsOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInventoryItemsOrderMutation, UpdateInventoryItemsOrderMutationVariables>(UpdateInventoryItemsOrderDocument, options);
      }
export type UpdateInventoryItemsOrderMutationHookResult = ReturnType<typeof useUpdateInventoryItemsOrderMutation>;
export type UpdateInventoryItemsOrderMutationResult = Apollo.MutationResult<UpdateInventoryItemsOrderMutation>;
export type UpdateInventoryItemsOrderMutationOptions = Apollo.BaseMutationOptions<UpdateInventoryItemsOrderMutation, UpdateInventoryItemsOrderMutationVariables>;
export const GetBusinessUnitBySlugDocument = gql`
    query getBusinessUnitBySlug($slug: String!) {
  getBusinessUnitBySlug(slug: $slug) {
    id
    name
  }
}
    `;
export function useGetBusinessUnitBySlugQuery(baseOptions: Apollo.QueryHookOptions<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>(GetBusinessUnitBySlugDocument, options);
      }
export function useGetBusinessUnitBySlugLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>(GetBusinessUnitBySlugDocument, options);
        }
export type GetBusinessUnitBySlugQueryHookResult = ReturnType<typeof useGetBusinessUnitBySlugQuery>;
export type GetBusinessUnitBySlugLazyQueryHookResult = ReturnType<typeof useGetBusinessUnitBySlugLazyQuery>;
export type GetBusinessUnitBySlugQueryResult = Apollo.QueryResult<GetBusinessUnitBySlugQuery, GetBusinessUnitBySlugQueryVariables>;
export const InventoryItemsByBusinessDocument = gql`
    query InventoryItemsByBusiness($id: Int!, $place: Place) {
  getInventoryItemsByBusinessUnit(businessUnitId: $id, place: $place) {
    id
    place
    name
    unitPrice
    minimumQuantity
    quantity
    positionIndex
    categories {
      id
      name
    }
    unitOfMeasure {
      id
      name
      abbreviation
    }
    stockUpdates {
      observation
      quantity
      createdAt
    }
    updatedAt
  }
}
    `;
export function useInventoryItemsByBusinessQuery(baseOptions: Apollo.QueryHookOptions<InventoryItemsByBusinessQuery, InventoryItemsByBusinessQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InventoryItemsByBusinessQuery, InventoryItemsByBusinessQueryVariables>(InventoryItemsByBusinessDocument, options);
      }
export function useInventoryItemsByBusinessLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InventoryItemsByBusinessQuery, InventoryItemsByBusinessQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InventoryItemsByBusinessQuery, InventoryItemsByBusinessQueryVariables>(InventoryItemsByBusinessDocument, options);
        }
export type InventoryItemsByBusinessQueryHookResult = ReturnType<typeof useInventoryItemsByBusinessQuery>;
export type InventoryItemsByBusinessLazyQueryHookResult = ReturnType<typeof useInventoryItemsByBusinessLazyQuery>;
export type InventoryItemsByBusinessQueryResult = Apollo.QueryResult<InventoryItemsByBusinessQuery, InventoryItemsByBusinessQueryVariables>;
export const CloseInventoryDocument = gql`
    mutation CloseInventory($data: CloseInventoryInput!) {
  closeInventory(data: $data) {
    id
    name
  }
}
    `;
export function useCloseInventoryMutation(baseOptions?: Apollo.MutationHookOptions<CloseInventoryMutation, CloseInventoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseInventoryMutation, CloseInventoryMutationVariables>(CloseInventoryDocument, options);
      }
export type CloseInventoryMutationHookResult = ReturnType<typeof useCloseInventoryMutation>;
export type CloseInventoryMutationResult = Apollo.MutationResult<CloseInventoryMutation>;
export type CloseInventoryMutationOptions = Apollo.BaseMutationOptions<CloseInventoryMutation, CloseInventoryMutationVariables>;
export const GetStockUpdateByInventoryItemIdDocument = gql`
    query GetStockUpdateByInventoryItemId($id: Int!, $startDate: String, $endDate: String) {
  getStockUpdateByInventoryItemId(
    id: $id
    startDate: $startDate
    endDate: $endDate
  ) {
    id
    quantity
    unitPrice
    observation
    updatedAt
  }
}
    `;
export function useGetStockUpdateByInventoryItemIdQuery(baseOptions: Apollo.QueryHookOptions<GetStockUpdateByInventoryItemIdQuery, GetStockUpdateByInventoryItemIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStockUpdateByInventoryItemIdQuery, GetStockUpdateByInventoryItemIdQueryVariables>(GetStockUpdateByInventoryItemIdDocument, options);
      }
export function useGetStockUpdateByInventoryItemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStockUpdateByInventoryItemIdQuery, GetStockUpdateByInventoryItemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStockUpdateByInventoryItemIdQuery, GetStockUpdateByInventoryItemIdQueryVariables>(GetStockUpdateByInventoryItemIdDocument, options);
        }
export type GetStockUpdateByInventoryItemIdQueryHookResult = ReturnType<typeof useGetStockUpdateByInventoryItemIdQuery>;
export type GetStockUpdateByInventoryItemIdLazyQueryHookResult = ReturnType<typeof useGetStockUpdateByInventoryItemIdLazyQuery>;
export type GetStockUpdateByInventoryItemIdQueryResult = Apollo.QueryResult<GetStockUpdateByInventoryItemIdQuery, GetStockUpdateByInventoryItemIdQueryVariables>;
export const DeleteInventoryItemDocument = gql`
    mutation DeleteInventoryItem($id: Int!) {
  deleteInventoryItem(id: $id) {
    id
  }
}
    `;
export function useDeleteInventoryItemMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>(DeleteInventoryItemDocument, options);
      }
export type DeleteInventoryItemMutationHookResult = ReturnType<typeof useDeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationResult = Apollo.MutationResult<DeleteInventoryItemMutation>;
export type DeleteInventoryItemMutationOptions = Apollo.BaseMutationOptions<DeleteInventoryItemMutation, DeleteInventoryItemMutationVariables>;
export const RefreshTokenDocument = gql`
    query RefreshToken($refreshToken: String!) {
  refreshToken(refreshToken: $refreshToken) {
    token
    refreshToken
  }
}
    `;
export function useRefreshTokenQuery(baseOptions: Apollo.QueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
      }
export function useRefreshTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RefreshTokenQuery, RefreshTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RefreshTokenQuery, RefreshTokenQueryVariables>(RefreshTokenDocument, options);
        }
export type RefreshTokenQueryHookResult = ReturnType<typeof useRefreshTokenQuery>;
export type RefreshTokenLazyQueryHookResult = ReturnType<typeof useRefreshTokenLazyQuery>;
export type RefreshTokenQueryResult = Apollo.QueryResult<RefreshTokenQuery, RefreshTokenQueryVariables>;
export const ForgotPasswordDocument = gql`
    mutation ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;
export function useForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ForgotPasswordMutation, ForgotPasswordMutationVariables>(ForgotPasswordDocument, options);
      }
export type ForgotPasswordMutationHookResult = ReturnType<typeof useForgotPasswordMutation>;
export type ForgotPasswordMutationResult = Apollo.MutationResult<ForgotPasswordMutation>;
export type ForgotPasswordMutationOptions = Apollo.BaseMutationOptions<ForgotPasswordMutation, ForgotPasswordMutationVariables>;
export const LoginDocument = gql`
    mutation Login($data: LoginInput!) {
  login(data: $data) {
    user {
      ...Me
      businessUnit {
        name
        slug
      }
    }
    token
    refreshToken
  }
}
    ${MeFragmentDoc}`;
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const UpdateMeDocument = gql`
    mutation UpdateMe($data: UpdateUserInput!) {
  updateMe(data: $data) {
    ...Me
  }
}
    ${MeFragmentDoc}`;
export function useUpdateMeMutation(baseOptions?: Apollo.MutationHookOptions<UpdateMeMutation, UpdateMeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateMeMutation, UpdateMeMutationVariables>(UpdateMeDocument, options);
      }
export type UpdateMeMutationHookResult = ReturnType<typeof useUpdateMeMutation>;
export type UpdateMeMutationResult = Apollo.MutationResult<UpdateMeMutation>;
export type UpdateMeMutationOptions = Apollo.BaseMutationOptions<UpdateMeMutation, UpdateMeMutationVariables>;
export const DestroyAccountDocument = gql`
    mutation DestroyAccount {
  destroyAccount
}
    `;
export function useDestroyAccountMutation(baseOptions?: Apollo.MutationHookOptions<DestroyAccountMutation, DestroyAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DestroyAccountMutation, DestroyAccountMutationVariables>(DestroyAccountDocument, options);
      }
export type DestroyAccountMutationHookResult = ReturnType<typeof useDestroyAccountMutation>;
export type DestroyAccountMutationResult = Apollo.MutationResult<DestroyAccountMutation>;
export type DestroyAccountMutationOptions = Apollo.BaseMutationOptions<DestroyAccountMutation, DestroyAccountMutationVariables>;
export const RegisterDocument = gql`
    mutation Register($data: RegisterInput!) {
  register(data: $data) {
    user {
      ...Me
    }
    token
    refreshToken
  }
}
    ${MeFragmentDoc}`;
export function useRegisterMutation(baseOptions?: Apollo.MutationHookOptions<RegisterMutation, RegisterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterMutation, RegisterMutationVariables>(RegisterDocument, options);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = Apollo.MutationResult<RegisterMutation>;
export type RegisterMutationOptions = Apollo.BaseMutationOptions<RegisterMutation, RegisterMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($data: ResetPasswordInput!) {
  resetPassword(data: $data)
}
    `;
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;